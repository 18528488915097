import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

import { Customer } from "../features/customer/customer";
import { Order } from "../schema/cart";
import { formatName, formatPrice, priceString } from "../utils/util";

export interface Address {
    line1: string
    line2: string
    city: string
    postcode: string
}

const addressText = (address: Address) => {
    const parts = [address.line1];
    if (address.line2 !== "") {
        parts.push("," + address.line2)
    }
    parts.push(address.city)
    return parts.join(", ")
}

type CustomerModalProps = {
    customer: Customer
    show: boolean
    amount: number
    items: Record<string, number>
    sessionId: string
    getCustomer: () => Customer
    onPaymentSuccess: (order: Order) => void
    onPaymentFailure: (error: any) => void
    onHide: () => void
    onCustomerUpdate: (customer: Customer) => void
}

export default function CustomerModal({ customer, show, amount, items, sessionId, getCustomer, onCustomerUpdate, onHide, onPaymentFailure, onPaymentSuccess }: CustomerModalProps) {
    const handleFieldUpdate = (field: string, value: string) => {
        onCustomerUpdate({ ...customer, [field]: value });
    }

    const handleOnHide = () => {
        onHide();
    }

    const handlePostcodeSearch = () => {
        getAddresses({
            variables: {
                postcode: customer.postcode,
            }
        })
    }

    const [getAddresses, { loading: addressLoading, error, data }] = useLazyQuery<{ addresses: Address[] }>(gql`
        query Addresses($postcode: String!){
            addresses(postcode: $postcode) {
                line1,
                line2,
                city,
                postcode,
            }
        }
    `);

    const handleChange = (value: string) => {
        if (!data) return
        const address = data.addresses[parseInt(value)];
        onCustomerUpdate({
            ...customer,
            address1: address.line1,
            address2: address.line2,
            city: address.city,
            postcode: address.postcode,
        })
    }

    const [paymentFailed, setPaymentFailed] = useState(false);

    const [orderWithPayment, { loading }] = useMutation<{ orderWithPayment: Order }>(gql`
        mutation OrderWithPayment($input: OrderPaymentInput!) {
            orderWithPayment(input: $input) {
                id,
                status,
                createdAt,
                updatedAt,
                sessionId
            }
        }
    `);

    useEffect(() => {
        if (!show || loading) {
            setPaymentFailed(false)
        }
    }, [loading, show])

    return <Modal
        show={show}
        onHide={handleOnHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Customer Details
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Row>
                    <Col md={6}>
                        <Form.Label htmlFor="inputFirstName">First Name <span className="text-danger">*</span></Form.Label>
                        <Form.Control id="inputFirstName" value={customer.firstName} required type="text" onChange={(e) => { handleFieldUpdate("firstName", e.target.value) }}></Form.Control>
                    </Col>
                    <Col md={6}>
                        <Form.Label htmlFor="inputLastName">Last Name</Form.Label>
                        <Form.Control id="inputLastName" value={customer.lastName} type="text" onChange={(e) => { handleFieldUpdate("lastName", e.target.value) }}></Form.Control>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <Form.Label htmlFor="inputEmail">Email</Form.Label>
                        <Form.Control id="inputEmail" value={customer.email} type="email" onChange={(e) => { handleFieldUpdate("email", e.target.value) }}></Form.Control>
                    </Col>
                    <Col md={6}>
                        <Form.Label htmlFor="inputPhone">Phone <span className="text-danger">*</span></Form.Label>
                        <Form.Control id="inputPhone" value={customer.phone} type="text" required onChange={(e) => { handleFieldUpdate("phone", e.target.value) }}></Form.Control>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={3}>
                        <Form.Label htmlFor="inputPostcode">Postcode</Form.Label>
                        <InputGroup>
                            <Form.Control id="inputPostcode" value={customer.postcode} type="text" onChange={(e) => { handleFieldUpdate("postcode", e.target.value) }}></Form.Control>
                            <Button variant="outline-secondary" onClick={handlePostcodeSearch}>
                                <i className="bi-search" style={{ fontSize: "1rem" }}></i>
                            </Button>
                        </InputGroup>
                    </Col>
                    {
                        !addressLoading && (!!data || !!error) &&
                        <Col md={{ offset: 3, span: 6 }}>
                            <Form.Label htmlFor="inputAddress">Select Address</Form.Label>
                            <Form.Select id="inputAddress" defaultValue="disabled" aria-label="Select address" onChange={(e) => handleChange(e.target.value)}>
                                {!data && <option value="disabled" disabled>Invalid postcode</option>}
                                {!!data && <option value="disabled" disabled>{data.addresses.length} address found</option>}
                                {
                                    data?.addresses.map((address, i) => {
                                        return <option value={i} key={i}>{addressText(address)}</option>
                                    })
                                }
                            </Form.Select>
                        </Col>
                    }
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <Form.Label htmlFor="inputAddress1">Address</Form.Label>
                        <Form.Control id="inputAddress1" value={customer.address1} type="text" onChange={(e) => { handleFieldUpdate("address1", e.target.value) }}></Form.Control>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <Form.Label htmlFor="inputAddress2">Address 2</Form.Label>
                        <Form.Control id="inputAddress2" value={customer.address2} type="text" onChange={(e) => { handleFieldUpdate("address2", e.target.value) }}></Form.Control>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <Form.Label htmlFor="inputCity">City</Form.Label>
                        <Form.Control id="inputCity" value={customer.city} type="text" onChange={(e) => { handleFieldUpdate("city", e.target.value) }}></Form.Control>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col xs={6}>
                        <PayPalScriptProvider
                            options={{
                                "client-id": process.env.REACT_APP_PP_KEY || "",
                                "locale": "en_GB",
                                "currency": "GBP",
                            }}
                        >
                            <PayPalButtons
                                style={{
                                    layout: "vertical",
                                    label: "pay",
                                    color: "white"
                                }}
                                // fundingSource="card"
                                disabled={customer.firstName.trim().length === 0 ||
                                    customer.phone.trim().length === 0}
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        intent: "CAPTURE",
                                        purchase_units: [{
                                            amount: {
                                                value: priceString(amount),
                                                currency_code: "GBP",
                                            }
                                        }],
                                        application_context: {
                                            brand_name: "Tre Colori Pizzeria",
                                            shipping_preference: "NO_SHIPPING",
                                            user_action: "PAY_NOW",
                                            landing_page: "BILLING"
                                        },
                                    })
                                }}
                                onApprove={(data, actions) => {
                                    const customer = getCustomer();
                                    return orderWithPayment({
                                        variables: {
                                            input: {
                                                payment: {
                                                    type: "sale",
                                                    nonce: data.orderID,
                                                    amount: formatPrice(amount),
                                                    details: "",
                                                    deviceData: "",
                                                    binData: "",
                                                },
                                                order: {
                                                    orderType: "PICK_UP",
                                                    name: formatName(customer.firstName, customer.lastName),
                                                    sessionId,
                                                    address: {
                                                        line1: customer.address1,
                                                        line2: customer.address2,
                                                        city: customer.city,
                                                        postcode: customer.postcode,
                                                    },
                                                    email: customer.email,
                                                    phone: customer.phone,
                                                    foods: Object.keys(items).map((foodId) => {
                                                        return {
                                                            foodId,
                                                            quantity: items[foodId],
                                                            notes: "",
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    }).then(({ data }) => {
                                        if (data) {
                                            onPaymentSuccess(data.orderWithPayment)
                                        }
                                    }).catch((err) => {
                                        setPaymentFailed(true);
                                        onPaymentFailure(err)
                                    })
                                }}
                            />
                        </PayPalScriptProvider>
                    </Col>
                    <p className="text-center text-danger fw-bold">{paymentFailed ? "Payment failed" : ""}</p>
                </Row>
            </Form>
        </Modal.Body>
    </Modal>
}
