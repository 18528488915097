import React from "react";

type SplashLayoutProps = {
    children?: React.ReactNode;
};

export default function SplashLayout({ children }: SplashLayoutProps) {
    return (
        <div id="splash" className="shadow-2-strong">
            <div className="mask d-flex align-items-center h-100">
                {children}
            </div>
        </div>
    )
}
