import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { User, Token } from "../layouts/admin";
import cookie from "cookie";
import SplashLayout from "../layouts/splash";
import { useNavigate } from "react-router-dom";

export interface UserToken {
    user: User
    token: Token
}

export default function UserLoginPage() {
    const [cred, setCred] = useState<{ email: string, pass: string }>({ email: "", pass: "" });
    const navigate = useNavigate();

    const handleChange = (field: string, value: string) => {
        setCred({
            ...cred,
            [field]: value,
        })
    }

    const [login, { loading, data, error }] = useMutation<{ userLogin: UserToken }>(gql`
        mutation Login($input: LoginReq!) {
            userLogin(req: $input) {
                user {
                    id,
                    name,
                    email,
                    status,
                    role
                }
                token {
                    id,
                    expiresAt,
                    refreshToken
                }
            }
        }
    `)

    useEffect(() => {
        if (!data) {
            return
        }
        const { token } = data.userLogin;
        document.cookie = cookie.serialize("auth-cookie", token.id, {
            expires: new Date(Date.parse(token.expiresAt)),
            path: "/",
        })
    }, [data])

    useEffect(() => {
        const { "auth-cookie": authCookie } = cookie.parse(document.cookie)
        if (authCookie) {
            navigate("/_orders")
        }
        return
    })

    const handleSubmit = async () => {
        try {
            await login({
                variables: {
                    input: {
                        email: cred.email,
                        password: cred.pass,
                    }
                }
            })
        }
        catch (error) {
            console.error(error)
        }
    }

    return (
        <SplashLayout>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} xl={4} className="position-fixed top-50 start-50 translate-middle">
                        <Form onSubmit={handleSubmit} className="bg-white rounded-1 shadow p-5">
                            <Form.Group className="form-outline mb-4">
                                <Form.Label className="form-label" htmlFor="inpEmail">Email address</Form.Label>
                                <Form.Control value={cred.email} type="email" id="inpEmail" onChange={(e) => { handleChange("email", e.target.value) }} />
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label htmlFor="inpPass">Password</Form.Label>
                                <Form.Control value={cred.pass} type="password" id="inpPass" onChange={(e) => { handleChange("pass", e.target.value) }} />
                            </Form.Group>
                            <Button type="submit" disabled={loading} className="btn-block" onClick={handleSubmit}>Sign in</Button>
                            {!loading && !!error && <p className="text-danger float-end">Login failed</p>}
                        </Form>
                    </Col>
                </Row>
            </Container>
        </SplashLayout>
    )
}
