import moment from "moment";
import * as uuid from "uuid";
import { CartState, parseCart, serializeCart } from "../schema/cart";

const LOCAL_STORE_KEY = "CART";

export const getLocalData = () => {
    const data = window.localStorage.getItem(LOCAL_STORE_KEY);
    if(!data) return undefined;
    const cart = parseCart(data);
    if (!cart) return undefined;
    cart.orders = cart.orders.filter((order) => {
        return !moment(order.createdAt).isBefore(moment().add(-1, "day"))
    });
    if (!cart.sessionId) {
        cart.sessionId = uuid.v4();
        setLocalData(cart);
    }
    return cart;
}

export const setLocalData = (cart: CartState) => {
    return window.localStorage.setItem(LOCAL_STORE_KEY, serializeCart(cart));
}
