import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

import "./index.scss";

import typeDefs from "./typedef"

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND,
  cache: new InMemoryCache(),
  typeDefs: typeDefs,
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
