import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BasicLayout from "../layouts/basic";

export default function PromotionPage() {
    return (
        <BasicLayout>
            <Container className="py-5">
                <Row>
                    <Col>
                        <h2 className="text-center text-uppercase page-title">Promotion</h2>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg={{ offset: 2, span: 8 }}>
                        <Row>
                            <Col className="bg-light gy-3 py-3">
                                <div className="my-1 decor text-center">
                                    <p className="m-0">Claim your <span className="text-danger">free pizza</span> after 6 stump.</p>
                                    <small>Please collect your loyalty stump from in store.</small>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </BasicLayout>
    );
}
