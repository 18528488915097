import { gql, useMutation } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import { Modal, Button, Col, Container, Row, Badge } from "react-bootstrap";
import { formatPrice, toTitleCase } from "../utils/util";

export interface Order {
    id: string
    name: string
    createdAt: string
    total: number
    status: string
    phone: string
    address: {
        line1: string
        line2: string
        city: string
        postcode: string
    },
    foods: {
        id: string
        name: string
        quantity: number
        status: string
    }[]
}

const status: Record<string, { nextStatus: string, color: string }> = {
    "INIT": {
        nextStatus: "PREPARING", color: "primary",
    },
    "PREPARING": {
        nextStatus: "READY", color: "warning"
    },
    "READY": {
        nextStatus: "DELIVERED", color: "success"
    },
    "DELIVERED": {
        nextStatus: "", color: "white"
    }
}

export interface OrderModalProps {
    order?: Order
    show: boolean
    onHide: () => void
}

export default function OrderModal({ order, show, onHide }: OrderModalProps) {
    const [updateStatus, { loading, error }] = useMutation<{ updateOrder: { id: string, status: string } }>(gql`
        mutation UpdateOrder($id: ID!, $input: OrderUpdateInput!) {
            updateOrder(id: $id, input: $input) {
                id,
                status
            }
        }
    `)

    const [showError, setShowError] = useState(false);
    const handleHide = () => {
        setShowError(false)
        onHide()
    }

    const handleClick = () => {
        if (!order) return;
        updateStatus({
            variables: {
                id: order.id,
                input: {
                    status: status[order.status].nextStatus,
                }
            }
        })
            .catch((error: any) => {
                console.error(error);
                setShowError(true);
            })
            .then((data) => {
                if (!data) return;
                handleHide()
            })
    }

    if (!order) return null
    return (
        <Modal onHide={handleHide} show={show} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    #{order.id.slice(-6).toUpperCase()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container id="cart" className="border border-2 rounded-1 mb-3">
                    <Row className="py-3 px-0 mx-2 border-bottom">
                        <Col className="px-0">
                            {order.foods.length || 0} Item{(order.foods.length || 0) > 1 ? "s" : ""} Ordered at <strong>{moment(order.createdAt).format("LT")}</strong>
                            {order.status !== "DELIVERED"
                                && <Badge className="float-end" bg={status[order.status].color}>{order.status}</Badge>
                            }
                        </Col>
                    </Row>
                    {
                        order.foods.map((food) => {
                            const { quantity } = food;
                            return (
                                <Row key={food.id} className="m-2 px-0">
                                    {/* <Col xs={3} className="ps-0">
                                        <span>
                                            <Button className="rounded-circle btn-transparent p-0 m-auto" onClick={() => { onRemove(foodId) }}>
                                                <i className="bi-dash-circle-fill remove-icon" style={{ fontSize: "1rem" }}></i>
                                            </Button>
                                            <span className="align-middle mx-1">{quantity}</span>
                                            <Button className="rounded-circle btn-transparent p-0 m-auto" onClick={() => { onAdd(foodId) }}>
                                                <i className="bi-plus-circle-fill add-icon" style={{ fontSize: "1rem" }}></i>
                                            </Button>
                                        </span>
                                    </Col> */}
                                    <Col xs={6} className="px-0 text-truncate">
                                        {food.name}
                                    </Col>
                                    <Col xs={3} className="pe-0">
                                        <span className="float-end">{quantity}</span>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Row className="py-3 px-0 mx-2 border-top">
                        <Col className="px-0" xs={6}>
                            <strong>Total: </strong>
                        </Col>
                        <Col xs={3} className="px-0">
                            <strong className="float-end">
                                {order.foods.map((f) => f.quantity).reduce((n, sum) => n + sum, 0)}
                            </strong>
                        </Col>
                        <Col xs={3} className="px-0">
                            <strong className="float-end">
                                {
                                    formatPrice(order.total || 0)
                                }
                            </strong>
                        </Col>
                    </Row>
                    <Row className="py-3 px-0 mx-2 border-top">
                        <Col className="px-0" xs={6}>
                            <b>{order.name}</b>
                            <p>{order.phone}</p>
                            <p className="m-0">{order.address.line1}</p>
                            <p className="m-0">{order.address.line2}</p>
                            <p className="m-0">{order.address.city}, {order.address.postcode}</p>
                        </Col>
                    </Row>
                    {status[order.status].nextStatus !== "" &&
                        <Row className="py-3 px-0 mx-2 border-top">
                            <Col className="px-0">
                                {!loading && !!error && showError && <strong className="text-danger">Failed to update status</strong>}
                                <strong className="float-end">
                                    <Button disabled={loading} className="float-end btn-checkout" onClick={handleClick}>
                                        {toTitleCase(status[order.status].nextStatus)}
                                    </Button>
                                </strong>
                            </Col>
                        </Row>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
