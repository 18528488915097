import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row, ListGroup, Toast, ToastContainer } from "react-bootstrap";
import { useQuery, gql } from '@apollo/client';
import * as uuid from "uuid";

import BasicLayout from "../layouts/basic";
import { Customer } from "../features/customer/customer";
import { formatPrice, normalizeName } from "../utils/util";
import CustomerModal from "../components/customer_modal";
import IngredientInfoModal from "../components/ingredient_modal";
import { classNames } from "../utils/util";
import { getLocalData, setLocalData } from "../utils/storage";
import { CartState, Order } from "../schema/cart";
import CustomerOrderModal from "../components/customer_order_modal";

enum FoodStatus {
    UNAVAILABLE = "UNAVAILABLE",
    AVAILABLE = "AVAILABLE"
}

type Food = {
    id: string
    name: string
    description: string
    price: {
        amount: number
    }
    order: number
    status: FoodStatus
}

type Category = {
    id: string
    name: string
    order: number
    foods: Food[]
}


const GET_CATEGORIES = gql`
    query {
        store {
            open
        }
        categories{
            id
            name
            order
            foods {
                id
                name
                description
                price {
                    amount
                }
                order
                status
            }
        }
    }
`;

export function calculateTotal(items: Record<string, number>, foods: Record<string, Food>): number {
    return Object.keys(items).reduce((prev, foodId) => {
        return prev + (foods[foodId]?.price.amount || 0) * items[foodId]
    }, 0)
}

type MyCategoryProps = {
    category: Category
    items: Record<string, number>;
    onAdd: (foodId: string) => void
}

type CartProps = {
    items: Record<string, number>;
    foods: Record<string, Food>;
    onAdd: (foodId: string) => void;
    onRemove: (foodId: string) => void;
    onCheckout?: () => void;
}

function Cart({ items, foods, onAdd, onRemove, onCheckout }: CartProps) {
    return (
        <Container id="cart" className="border border-2 rounded-1 mb-3">
            <Row className="py-3 px-0 mx-2 border-bottom">
                <Col className="px-0">
                    Items:
                </Col>
            </Row>
            {
                (Object.keys(items).length === 0) && (
                    <Row>
                        <Col sx={3} className="my-2 text-center">
                            No item to order
                        </Col>
                    </Row>
                )
            }
            {
                Object.keys(items).map((foodId) => {
                    const food = foods[foodId];
                    const quantity = items[foodId];
                    if (!food) return null;
                    return (
                        <Row key={foodId} className="m-2 px-0">
                            <Col xs={3} className="ps-0">
                                <span>
                                    <Button className="rounded-circle btn-transparent p-0 m-auto" onClick={() => { onRemove(foodId) }}>
                                        <i className="bi-dash-circle-fill remove-icon" style={{ fontSize: "1rem" }}></i>
                                    </Button>
                                    <span className="align-middle mx-1">{quantity}</span>
                                    <Button className="rounded-circle btn-transparent p-0 m-auto" onClick={() => { onAdd(foodId) }}>
                                        <i className="bi-plus-circle-fill add-icon" style={{ fontSize: "1rem" }}></i>
                                    </Button>
                                </span>
                            </Col>
                            <Col xs={6} className="px-0 text-truncate">
                                {food.name}
                            </Col>
                            <Col xs={3} className="pe-0">
                                <span className="float-end">{formatPrice(food.price.amount * quantity)}</span>
                            </Col>
                        </Row>
                    )
                })
            }
            <Row className="py-3 px-0 mx-2 border-top">
                <Col className="px-0">
                    <span>
                        <strong>Total: </strong>
                        <strong className="float-end">
                            {
                                formatPrice(calculateTotal(items, foods))
                            }
                        </strong>
                    </span>
                </Col>
            </Row>
            <Row className="py-3 px-0 mx-2 border-top">
                <Col className="px-0">
                    <small className="text-danger text-sm">* Orders are collection only</small>
                    <strong className="float-end">
                        <Button className="float-end btn-checkout" disabled={Object.keys(items).length === 0 || !onCheckout} onClick={onCheckout}>
                            {!onCheckout ? "Kitchen Closed" : "Checkout"}
                        </Button>
                    </strong>
                </Col>
            </Row>
        </Container>
    );
}

function MyCategory({ category, items, onAdd }: MyCategoryProps) {
    return (
        <ListGroup.Item className="px-0 list-transparent">
            <h4 className="text-green" id={normalizeName(category.name)}>{category.name}</h4>
            {
                category.foods.map((food) => {
                    if (food.status === FoodStatus.UNAVAILABLE) return null
                    return (
                        <ListGroup.Item className="px-0 list-transparent border-0 position-relative align-middle clearfix" key={food.id}>
                            <div className="float-end">
                                <span className="align-middle me-2">{formatPrice(food.price.amount)}</span>
                                <Button className={classNames("btn-circle p-0 m-0", !items[food.id] ? "btn-plus" : "btn-number")} onClick={() => { onAdd(food.id) }}>
                                    {!items[food.id] ?
                                        <i className="bi-plus align-middle"></i> :
                                        <span style={{ fontSize: "0.8rem" }}>{items[food.id]}</span>
                                    }
                                </Button>
                            </div>
                            <strong className="">{food.name}</strong>
                            {food.description.length !== 0 && <p>{food.description}</p>}
                        </ListGroup.Item>
                    )
                })
            }
        </ListGroup.Item>
    )
}

export default function ProductsPage() {
    const [ingModalShow, setIngModalShow] = useState(false);
    const [customerModalShow, setCustomerModalShow] = useState(false);
    const [orderModalShow, setOrderModalShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [paymentDone, setPaymentDone] = useState(false);

    const categories: Category[] = [];
    const foods: Record<string, Food> = {};

    const { loading, error, data } = useQuery<{ store: { open: boolean }, categories: Category[] }>(GET_CATEGORIES);
    if (!loading && !error && data) {

        categories.splice(0, categories.length);
        data.categories.forEach((cat) => {
            if (cat.foods.length) {
                categories.push(cat)

                cat.foods.forEach((food) => {
                    foods[food.id] = food
                })
            }
        });
    }

    var initialState: CartState = {
        items: {},
        customer: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address1: "",
            address2: "",
            city: "",
            postcode: "",
        },
        orders: [],
        sessionId: uuid.v4(),
    }


    const getCart = () => {
        const localData = getLocalData();
        return localData || initialState
    }

    initialState = getCart();

    const [cart, setCart] = useState<CartState>(initialState);
    const handleAdd = function (foodId: string) {
        const items = Object.assign({}, cart.items);

        items[foodId] = (items[foodId] || 0) + 1;
        saveItems(items);
    }

    const handleRemove = function (foodId: string) {
        const items = Object.assign({}, cart.items);
        if (!items[foodId]) return;

        items[foodId]--;
        if (items[foodId] === 0) delete items[foodId];

        saveItems(items);
    }

    const handleShowOrders = () => {
        setOrderModalShow(true);
    }

    const onOrderModalHide = () => {
        setOrderModalShow(false);
    }

    const saveItems = (items: Record<string, number>) => {
        const newCart = {
            ...cart,
            items,
        };

        setCart(newCart);
    }

    const saveCustomer = (customer: Customer) => {
        const newCart = {
            ...cart,
            customer,
        };
        setCart(newCart);
    }

    const handleCustomer = (customer: Customer) => {
        saveCustomer(customer);
        // setCustomerModalShow(false);
        // setPaymentModalShow(true);
    }

    const handleCheckout = () => {
        setCustomerModalShow(true);
    }

    useEffect(() => {
        setLocalData(cart);
    }, [cart])

    const handlePaymentSuccess = (order: Order) => {
        setPaymentDone(true);
        setShowToast(true);
        setCustomerModalShow(false);
        setCart({
            ...cart,
            items: {},
            orders: [order, ...cart.orders],
        });
    }

    return (
        <BasicLayout>
            <Container fluid className="px-2 py-5" style={{ position: "relative" }}>
                <Row className="d-flex">
                    <Image fluid src="/images/menu_cover.jpg" className="flex-grow-1"></Image>
                </Row>
                <Row className="mt-5">
                    <IngredientInfoModal show={ingModalShow} onHide={() => setIngModalShow(false)} />
                    <CustomerModal
                        customer={cart.customer}
                        items={cart.items}
                        sessionId={cart.sessionId}
                        amount={calculateTotal(cart.items, foods)}
                        show={customerModalShow}
                        getCustomer={() => getCart().customer}
                        onHide={() => setCustomerModalShow(false)}
                        onCustomerUpdate={handleCustomer}
                        onPaymentSuccess={handlePaymentSuccess}
                        onPaymentFailure={() => { }}
                    />
                    {/* {
                        cart.customer && <PaypalModal amount={calculateTotal(cart.items, foods)} items={cart.items} customer={cart.customer} show={paymentModalShow} onSuccess={handlePaymentSuccess} onFailure={(error) => { console.error(error) }} onHide={() => setPaymentModalShow(false)} />
                        // cart.customer && <PaymentModal customer={cart.customer} amount={calculateTotal(cart.items, foods)} show={paymentModalShow} onHide={() => setPaymentModalShow(false)} onSubmit={handlePayment} />
                    } */}
                    <ToastContainer position="top-end" containerPosition="fixed" className="p-3">
                        <Toast show={showToast && paymentDone} bg="success" onClose={() => setShowToast(false)} delay={3000}>
                            <Toast.Header>
                                {/* <img rc="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
                                <strong className="me-auto">Tre Colori Pizzeria</strong>
                                <small className="text-muted">just now</small>
                            </Toast.Header>
                            <Toast.Body>
                                <b>Payment Successful!!!</b>
                                <p>Your pizza will be ready to collect in 10 minutes</p>
                            </Toast.Body>
                        </Toast>
                    </ToastContainer>
                    <CustomerOrderModal
                        show={orderModalShow}
                        onHide={onOrderModalHide}
                    />
                    <Col lg={{ offset: 2, span: 8 }}>
                        <Row>
                            <Col>
                                <h2>Place Your Order</h2>
                                <Button className="btn-light btn-transparent px-0" onClick={() => { setIngModalShow(true) }}>
                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                    <span className="ms-2">Allergens & Ingredients Information</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3} className="d-none d-lg-block d-xl-block sticky-top">
                                <div className="sticky-top pt-3">
                                    <h3>Menu</h3>
                                    <ListGroup variant="flush">
                                        {
                                            categories.map((category) => {
                                                return (
                                                    <ListGroup.Item className="list-transparent px-0 menuItem" action href={"#" + normalizeName(category.name)} key={category.id}>
                                                        {category.name}
                                                    </ListGroup.Item>
                                                )
                                            })
                                        }
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col lg={5} className="pt-3">
                                <div>
                                    <ListGroup variant="flush">
                                        {
                                            categories.map((category) => { return <MyCategory key={category.id} items={cart.items} category={category} onAdd={handleAdd} /> })
                                        }
                                    </ListGroup>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="sticky-top pt-3">
                                    <h3>Your Order
                                        {getLocalData()?.orders.length ?
                                            <Button className="rounded-circle btn-light btn-transparent" onClick={handleShowOrders}>
                                                <i className="fas fa-light fa-pizza-slice fa-faw fa-2x fa-beat" style={{ color: "#ff322f" }} aria-hidden="true"></i>
                                            </Button>
                                            : ""
                                        }
                                    </h3>
                                    <Cart items={cart.items} foods={foods} onAdd={handleAdd} onRemove={handleRemove} onCheckout={data?.store.open ? handleCheckout : undefined} />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </BasicLayout>
    )
}
