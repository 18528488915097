
export function normalizeName(name: string): string {
    return name.split("")
        .filter((ch) => { return /\w/.test(ch) })
        .join("")
}

export function formatPrice(price: number): string {
    return `£${priceString(price)}`
}

export function priceString(price: number) {
    return Number(price).toFixed(2)
}

export function formatName(fName: string, lName: string) {
    const parts = [];
    if (fName !== "") {
        parts.push(fName);
    }
    if (lName !== "") {
        parts.push(lName);
    }
    return parts.join(" ")
}

export function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(' ')
}

export function toTitleCase(str: string) {
    return str.at(0) + str.substr(1).toLowerCase()
}
