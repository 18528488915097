import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface CartState {
    items: Record<string, number>
}

const initialState: CartState = {
    items: {},
}

const STORE_KEY = "CART"

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updateCart: (state, action: PayloadAction<CartState>) => {
            const {payload} = action
            window.localStorage.setItem(STORE_KEY, JSON.stringify(payload))
            return payload
        },

        fetchCart: (state) => {
            const data = window.localStorage.getItem(STORE_KEY);
            if (!data) {
                return initialState
            }
            try {
                return JSON.parse(data) as CartState
            } catch {
                return initialState
            }
        },

        resetCart: (state) => {
            window.localStorage.setItem(STORE_KEY,JSON.stringify(initialState))
            return initialState
        }
    }
})

export const { updateCart, fetchCart, resetCart } = cartSlice.actions

export const selectCart = (state: RootState) => state.cart;

export default cartSlice.reducer;
