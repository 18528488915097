import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import {
    GoogleMap, MarkerF,
    LoadScript
} from '@react-google-maps/api';

import BasicLayout from "../layouts/basic";
const containerStyle = {
    width: '300px',
    height: '300px'
};

const center = {
    lat: 51.527623,
    lng: -0.076905637
};

export default function ContactPage() {
    const [loaded, setLoaded] = useState(false);

    return (
        <BasicLayout>
            <Container className="py-5">
                <Row>
                    <Col>
                        <h2 className="text-center text-uppercase page-title">Find Us</h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ offset: 1, span: 10 }}>
                        <LoadScript googleMapsApiKey="AIzaSyCPsnChM01pXthWY6_xsNC9q6Irp84MdIg" onLoad={() => setLoaded(true)}>
                            {
                                loaded &&
                                <GoogleMap
                                    mapContainerClassName="float-start me-3"
                                    mapContainerStyle={containerStyle}
                                    center={center}

                                    zoom={18}
                                >
                                    <MarkerF
                                        position={center}
                                        title="Tre Colori Pizzeria"
                                        label={{ text: "Tre Colori Pizzeria" }}
                                        animation={window.google.maps.Animation.BOUNCE}
                                    />
                                </GoogleMap>
                            }
                        </LoadScript>
                        {
                            !loaded && <div style={containerStyle} className="float-start me-3"></div>
                        }
                        <div className="mt-3">
                            <strong>Tre Colori Pizzeria</strong>
                            <div className="mt-2">
                                <p className="my-0">19, Hackney Road</p>
                                <p className="my-0">London, E2 7NX</p>
                                <p className="my-0">Contact: 020 3487 0731</p>
                                <p className="my-0">Email: info@trecolori.co.uk</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BasicLayout>
    );
}
