import React from "react";
import { Container, Carousel, Col, Row } from "react-bootstrap";
import BasicLayout from "../layouts/basic";

type CarouselItem = {
    src: string;
    header: string;
    caption: string;
};

const items: CarouselItem[] = [
    {
        src: "/images/image1_small.jpeg",
        header: "First slide label",
        caption: "Nulla vitae elit libero, a pharetra augue mollis interdum.",
    },
    {
        src: "/images/image2_small.jpeg",
        header: "Second slide label",
        caption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
        src: "/images/image3_small.jpeg",
        header: "Third slide label",
        caption: "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
    },
    {
        src: "/images/image4_small.jpeg",
        header: "Third slide label",
        caption: "Praesent commodo cursus magna, vel scelerisque nisl consectetur.",
    },
]

export default function Home() {
    return (
        <BasicLayout>
            <Container fluid className="px-0">
                <Carousel pause={false} interval={4500}>
                    {items.map((item, i) => {
                        return (
                            <Carousel.Item key={i}>
                                <img
                                    className="d-block w-100"
                                    src={item.src}
                                    alt={item.header}
                                />
                                {/* <Carousel.Caption>
                                    <h3>{item.header}</h3>
                                    <p>
                                        {item.caption}
                                    </p>
                                </Carousel.Caption> */}
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
                <Container id="text-mainpage">
                    <Row className="text-mainpage-color">
                        <div>
                            <img id="logo" src="/images/trecolori_long_logo.png" className="mx-auto d-block" alt="logo" />
                            <h2 className="text-center text-white">Authentic Italian wood fired pizzeria</h2>
                            <Col className="text-center text-white">
                                <h3>Shoreditch</h3>
                                <h4>Opening hours</h4>
                                <p className="mb-1">Monday to Sunday, 12:00 PM till 11:00 PM</p>
                                <a className="telephone" href="tel:020 3487 0731">020 3487 0731</a>
                            </Col>
                            <Col className="mx-auto mt-3 d-flex justify-content-center">
                                <a className="btn btn-header btn-lg" href="/">Order now</a>
                            </Col>
                        </div>
                    </Row>
                </Container>
            </Container>
        </BasicLayout>
    );
}
