import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cookie from "cookie"
import NavBar from "../components/navbar";
import { gql, useLazyQuery } from "@apollo/client";

type AdminLayoutProps = {
    children?: React.ReactNode;
};

export interface User {
    id: string
    name: string
    email: string
    status: string
    role: string
}

export interface Token {
    id: string
    expiresAt: string
    refreshToken: string
}

export default function AdminLayout({ children }: AdminLayoutProps) {
    const [user, setUser] = useState<User>();
    const navigate = useNavigate();

    const [getMe, { data, loading }] = useLazyQuery<{ me: User }>(gql`
        query User {
            user {
                id,
                name,
                email,
                status,
                role,
            }
        }
    `)

    useEffect(() => {
        const cookies = cookie.parse(document.cookie || "");
        if (!cookies["auth-cookie"]) {
            navigate("/")
            return
        }

        if (user) {
            return
        }

        if (!loading) {
            try {
                getMe()
            } catch (error) {
                console.error(error);
            }
        }

    }, [user, loading, getMe, navigate]);

    useEffect(() => {
        if (!loading && data) {
            setUser(data.me);
        }
    }, [loading, data])

    return (
        <>
            <NavBar menu={[
                { name: "Orders", link: "/_orders" },
                { name: "History", link: "/_history" },
            ]} />
            {children}
            {/* <footer className="bg-light fixed-bottom">
                <div className="shadow text-center p-3">
                    © {(new Date()).getFullYear()} <a className="text-dark" href="/about">Tre Colori Pizzeria</a>
                </div>
            </footer> */}
        </>
    )
}
