import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import AboutUsPage from "./pages/about_us";
import Home from "./pages/home";

import './App.scss';
import PromotionPage from './pages/promotion';
import ContactPage from './pages/contact';
import ProductsPage from './pages/products';
import UserLoginPage from './pages/user_login';
import AdminOrdersPage from './pages/admin_orders';
import AdminOrderHistoryPage from './pages/admin_order_history';

const router = createBrowserRouter([
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/about",
    element: <AboutUsPage />,
  },
  {
    path: "/promotion",
    element: <PromotionPage />,
  },
  {
    path: "/contact",
    element: <ContactPage />,
  },
  {
    path: "/",
    element: <ProductsPage />,
  },
  {
    path: "/_login",
    element: <UserLoginPage />,
  },
  {
    path: "/_orders",
    element: <AdminOrdersPage />,
  },
  {
    path: "/_history",
    element: <AdminOrderHistoryPage />,
  },
  {
    path: "/*",
    element: <Navigate to="/" />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} fallbackElement={<Home />} />
  );
}

export default App;
