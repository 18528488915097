import React from "react";
import NavBar from "../components/navbar";

type BasicLayoutProps = {
    children?: React.ReactNode;
};

export default function BasicLayout({ children }: BasicLayoutProps) {
    return (
        <>
            <NavBar menu={[
                { name: "Home", link: "/home" },
                { name: "About Us", link: "/about" },
                { name: "View Menu", link: "/" },
                { name: "Promotion", link: "/promotion" },
                { name: "Find Us", link: "/contact" },
            ]} />
            {children}
            {/* <footer className="bg-light fixed-bottom">
                <div className="shadow text-center p-3">
                    © {(new Date()).getFullYear()} <a className="text-dark" href="/about">Tre Colori Pizzeria</a>
                </div>
            </footer> */}
        </>
    )
}
