import { gql } from "@apollo/client"
export default gql`
type Address {
  line1: String!
  line2: String
  city: String!
  postcode: String!
}

input AddressInput {
  line1: String!
  line2: String
  city: String!
  postcode: String!
}

type Audio {
  id: ID!
  name: String!
  url: String!
  format: String!
  length: Int!
}

type Category {
  id: ID!
  name: String!
  description: String!
  order: Int!
  parentId: String
  parent: Category
  foods: [Food!]!
  createdAt: Time!
  updatedAt: Time!
}

input CategoryFilter {
  name: String
}

type Customer {
  id: ID!
  name: String!
  email: String!
  phone: String!
  address: Address!
  status: CustomerStatus!
  createdAt: Time!
  updatedAt: Time!
}

input CustomerCreateInput {
  name: String!
  email: String!
  phone: String!
  address: AddressInput!
  password: String
}

input CustomerFilter {
  id: ID
  email: String
  phone: String
}

input CustomersFilter {
  name: String
  postcode: String
  status: CustomerStatus
}

enum CustomerStatus {
  INACTIVE
  ACTIVE
}

type CustomerToken {
  customer: Customer!
  token: Token!
}

input CustomerUpdateInput {
  name: String
  email: String
  phone: String
  address: AddressInput
  status: CustomerStatus
  newPassword: String
  oldPassword: String
}

enum Dietary {
  MEAT
  VEGAN
  VEGETARIAN
}

type Food {
  id: ID!
  name: String!
  categoryIds: [String]!
  categories: [Category!]!
  description: String!
  status: FoodStatus!
  price: Price!
  mediaIds: [String!]!
  media: [Media!]!
  order: Int!
  ingredientIds: [String!]!
  ingredients: [Ingredient!]!
  weight: Float!
  size: Size!
  dietary: Dietary
  halal: Boolean
  notes: String
  additional: String
  options: [FoodOption!]!
  createdAt: Time!
  updatedAt: Time!
}

input FoodCreateInput {
  name: String!
  categoryIds: [ID!]!
  description: String!
  status: FoodStatus!
  price: PriceInput!
  mediaIds: [ID!]!
  order: Int!
  ingredientIds: [ID!]!
  weight: Float!
  size: SizeInput!
  dietary: Dietary
  halal: Boolean
  notes: String
  additional: String
}

type FoodOption {
  id: ID!
  name: String!
  description: String!
  status: FoodStatus!
  price: Price!
  mediaIds: [String!]!
  media: [Media!]!
  ingredientIds: [String!]!
  ingredients: [Ingredient!]!
  weight: Float!
  size: Size!
  dietary: Dietary
  halal: Boolean
  notes: String
  additional: String
}

input FoodsFilter {
  name: String
  categories: [String!]
  status: FoodStatus
  minPrice: Float
  maxPrice: Float
  currency: String
  ingredients: [String!]
  dietary: Dietary
  halal: Boolean
}

enum FoodStatus {
  UNAVAILABLE
  AVAILABLE
}

input FoodUpdateInput {
  name: String
  categoryIds: [ID!]
  description: String
  status: FoodStatus
  price: PriceInput
  mediaIds: [ID!]
  order: Int
  ingredientIds: [ID!]
  weight: Float
  size: SizeInput
  dietary: Dietary
  halal: Boolean
  notes: String
  additional: String
}

type Image {
  id: ID!
  name: String!
  url: String!
  format: String!
  height: Int!
  width: Int!
}

type Ingredient {
  id: ID!
  name: String!
  allergic: Boolean
}

input LoginReq {
  email: String!
  password: String!
}

union Media = Image | Video | Audio

type Order {
  id: ID!
  orderType: OrderType!
  customerId: String
  customer: Customer
  name: String!
  address: Address
  phone: String
  email: String
  foods: [OrderFood!]!
  status: OrderStatus!
  statusUpdatedAt: Time!
  total: Float!
  discount: Float!
  currency: String!
  paymentStatus: PaymentStatus!
  paymentUpdatedAt: Time!
  paymentReference: String
  createdAt: Time!
  updatedAt: Time!
}

input OrderCreateInput {
  orderType: OrderType!
  customerId: ID
  name: String!
  address: AddressInput
  email: String
  phone: String
  foods: [OrderFoodInput!]!
}

type OrderFood {
  id: ID!
  food: Food!
  name: String!
  price: Price!
  weight: Float!
  size: Size!
  quantity: Int!
  notes: String!
  status: OrderStatus!
  statusUpdatedAt: Time!
  createdAt: Time!
  addIds: [String!]!
  adds: [FoodOption!]!
  subIds: [String!]!
  subs: [FoodOption!]!
}

input OrderFoodInput {
  foodId: ID!
  quantity: Int!
  notes: String!
}

input OrderPaymentInput {
  payment: PaymentDetailsInput!
  order: OrderCreateInput!
}

input OrdersFilter {
  orderType: OrderType
  status: OrderStatus
  customerId: ID
}

enum OrderStatus {
  INIT
  RECEIVED
  PREPARING
  READY
  DELIVERED
  CANCELED
}

enum OrderType {
  DINE_IN
  PICK_UP
  DELIVER
}

input OrderUpdateInput {
  orderType: OrderType
  customerId: ID
  name: String
  address: AddressInput
  phone: String
  foods: [OrderFoodInput!]
  status: OrderStatus
  discount: Float
  paymentStatus: PaymentStatus
}

input PaymentDetailsInput {
  type: String!
  nonce: String!
  amount: String!
  details: String!
  binData: String!
  deviceData: String!
}

input PaymentInput {
  orderId: String!
  token: String!
}

enum PaymentStatus {
  UNPAID
  INITIATED
  PROCESSING
  DONE
  FAILED
  DECLINED
  CANCELED
}

type Price {
  amount: Float!
  currency: String!
}

input PriceInput {
  amount: Float!
  currency: String!
}

type Size {
  amount: Int!
  unit: String!
}

input SizeInput {
  amount: Int!
  unit: String!
}

scalar Time

type Token {
  id: String!
  expiresAt: Time!
  refreshToken: String!
}

type User {
  id: ID!
  name: String!
  email: String!
  phone: String!
  status: UserStatus!
  role: UserRole!
  createdAt: Time!
  updatedAt: Time!
}

input UserCreateInput {
  name: String!
  email: String!
  phone: String!
  status: UserStatus!
  role: UserRole!
}

input UserFilter {
  id: ID
  email: String
  phone: String
}

enum UserRole {
  ADMIN
  STAFF
}

input UsersFilter {
  status: UserStatus
}

enum UserStatus {
  INACTIVE
  ACTIVE
}

type UserToken {
  user: User!
  token: Token!
}

input UserUpdateInput {
  name: String
  email: String
  phone: String
  status: UserStatus
  role: UserRole
  newPassword: String
  oldPassword: String
}

type Video {
  id: ID!
  name: String!
  url: String!
  format: String!
  height: Int!
  width: Int!
  length: Int!
  fps: Int!
}
`
