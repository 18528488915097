import React from "react";
import { Modal } from "react-bootstrap";

export interface IngredientInfoModalProps {
    show: boolean
    onHide: () => void
}

export default function IngredientInfoModal(props: IngredientInfoModalProps) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Allergens & Ingredients Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <b>
                    We can offer accurate information on ingredients, however, due to the open-plan nature of our kitchens, we are unable to guarantee that dishes are ‘free from’ allergens.
                </b>
                <p>
                    If you or someone you’re ordering for has any specific dietary requirements, please alert a member of staff prior to ordering.
                </p>
                <p>
                    (v) denotes vegetarian, (ve) denotes vegan
                </p>
                <p>
                    Food allergens are:
                </p>
                <p>
                    Celery (including celeriac), Cereals containing gluten (such as, rye, barley, and oats), Crustaceans (such as prawns, crabs and lobsters), Eggs, Fish, Lupin, Milk, Molluscs (such as mussels and oysters), Mustard, Sesame, Soya, Sulphur Dioxide and Sulphites (if they are at a concentration of more than 10mg/kg or 10mg/litre, in products ready for consumption) Peanuts and Tree Nuts (such as almonds, hazelnuts, walnuts, brazil nuts, cashews, pecans, pistachios and macadamia nuts).
                </p>
            </Modal.Body>
        </Modal>
    );
}
