import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";

type NavbarProps = {
    menu: {
        name: string;
        link: string;
    }[];
};

export default function NavBar({ menu }: NavbarProps) {
    let location = useLocation();
    return (
        <Navbar id="main" expand="xl" className="shadow-sm bg-light">
            <Container fluid className="justify-content-end">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto" activeKey={location.pathname}>
                        {menu.map((item, i) => {
                            return (
                                <Nav.Link className="mx-auto px-5 text-uppercase" key={i} href={item.link}>{item.name}</Nav.Link>
                            )
                        })}
                    </Nav>
                    <div id="nav-media" className="position-relative">
                        <ul className="navbar-nav d-flex flex-row float-end">
                            <li className="nav-item me-3 me-lg-0">
                                <a className="nav-link" href="tel:020 3487 0731">
                                    <i className="fa-solid fa-phone"></i>
                                </a>
                            </li>
                            <li className="nav-item me-3 me-lg-0">
                                <a className="nav-link" href="https://www.facebook.com/Trecolori" target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="nav-item me-3 me-lg-0">
                                <a className="nav-link" href="https://www.tiktok.com/@tre_colori_resturant" target="_blank" rel="noreferrer">
                                    <i className="fab fa-tiktok"></i>
                                </a>
                            </li>
                            <li className="nav-item me-3 me-lg-0">
                                <a className="nav-link" href="https://instagram.com/tre_colori_shoreditch" target="_blank" rel="noreferrer">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};
