import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import OrderModal, { Order } from "../components/order_modal";
import AdminLayout from "../layouts/admin";
import { formatPrice } from "../utils/util";

function formatTime(time: string) {
    return moment(time).fromNow()
}

const colorMap: Record<string, string> = {
    "INIT": "primary",
    "PREPARING": "warning",
    "READY": "success"
}

function playSound(url: string) {
    const audio = new Audio(url);
    audio.play();
}

export default function AdminOrdersPage() {
    const { data, startPolling, stopPolling, previousData } = useQuery<{ orders: Order[] }>(gql`
    query Order {
        orders {
            id
            name
            createdAt
            total
            status
            phone
            address {
                line1
                line2
                city
                postcode
            }
            foods {
                id
                name
                quantity
                status
            }
        }
    }`)

    const [showOrder, setShowOrder] = useState<Order>();

    useEffect(() => {
        startPolling(10000);
        return stopPolling
    })

    useEffect(() => {
        if (data && previousData) {
            const { orders } = data;
            const { orders: prevOrders } = previousData;
            if (orders.length > prevOrders.length) {
                playSound("./audios/notification.mp3")
            } else if (orders.length > 0 && prevOrders.length > 0 && orders[orders.length - 1].id !== prevOrders[prevOrders.length - 1].id) {
                playSound("./audios/notification.mp3")
            }
        }
    })

    return (
        <AdminLayout>
            <Container fluid className="p-3">
                <Row>
                    <OrderModal order={showOrder} show={!!showOrder} onHide={() => { setShowOrder(undefined) }}></OrderModal>
                    {
                        data?.orders.map((order) => {
                            if (order.status === "DELIVERED") return null;
                            return (
                                <Col key={order.id} lg={3} className="p-2">
                                    <Card onClick={() => { setShowOrder(order) }} bg={colorMap[order.status]}>
                                        <Card.Body>
                                            <Card.Title>
                                                {order.name} ({order.phone})
                                                <span className="float-end">{formatPrice(order.total)}</span>
                                            </Card.Title>
                                            <Card.Subtitle>{formatTime(order.createdAt)}</Card.Subtitle>

                                            <ul className="my-2">
                                                {
                                                    order.foods.map((food) => {
                                                        return (
                                                            <li key={food.id}> {food.name} x {food.quantity} </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </AdminLayout>
    )
}
