import moment from "moment";
import { Modal, Col, Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getLocalData } from "../utils/storage";
import { gql, useQuery } from "@apollo/client";
import { Order } from "../schema/cart";

export interface CustomerOrderModalProps {
    show: boolean
    onHide?: () => void
}


const statusText: Record<string, { text: string }> = {
    "INIT": {
        text: "Placed",
    },
    "PREPARING": {
        text: "Preparing"
    },
    "READY": {
        text: "Ready"
    },
    "DELIVERED": {
        text: "Delivered"
    }
}

export default function CustomerOrderModal({ show, onHide }: CustomerOrderModalProps) {
    const localData = getLocalData();

    const [orders, setOrders] = useState(localData?.orders || []);
    const { data, startPolling, stopPolling } = useQuery<{ customerOrders: Order[] }>(gql`
        query Orders($sessionId: ID!) {
            customerOrders(sessionId: $sessionId) {
                id
                name
                createdAt
                updatedAt
                status
                sessionId
            }
        }
    `, {
        variables: {
            sessionId: localData?.sessionId || "",
        }
    });

    useEffect(() => {
        if (data?.customerOrders) {
            setOrders(data.customerOrders);
        }
    }, [data])

    useEffect(() => {
        if (localData?.sessionId && show) {
            startPolling(30 * 1000);
            return () => {
                stopPolling()
            }
        }
    });

    if (!orders || !orders.length) return null;

    return (
        <Modal onHide={onHide} show={show} centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Orders for {orders[0].name}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container id="cart" className="border border-2 rounded-1 mb-3">
                    <Row key="header" className="m-2 px-0">
                        <Col className="pe-0"><strong>Order ID</strong></Col>
                        <Col className="pe-0"><strong>Status</strong></Col>
                        <Col className="px-0"><strong>Updated At</strong></Col>
                        <Col className="px-0"><strong>Created At</strong></Col>
                    </Row>
                    {
                        orders.map((order) => {
                            return (
                                <Row key={order.id} className="m-2 px-0">
                                    <Col className="pe-0">
                                        {order.id.slice(-6).toUpperCase()}
                                    </Col>
                                    <Col className="pe-0">
                                        {statusText[order.status].text}
                                    </Col>
                                    <Col className="px-0 text-truncate">
                                        {moment(order.updatedAt).format("LT")}
                                    </Col>
                                    <Col className="px-0 text-truncate">
                                        {moment(order.createdAt).format("LT")}
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
