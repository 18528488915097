import Ajv, { JTDSchemaType } from "ajv/dist/jtd";
import { Customer } from "../features/customer/customer";

const ajv = new Ajv();

const cartStateSchema: JTDSchemaType<CartState> = {
    properties: {
        items: {
            values: { type: "uint32" }
        },
        customer: {
            properties: {
                id: { type: "string" },
                firstName: { type: "string" },
                lastName: { type: "string" },
                email: { type: "string" },
                phone: { type: "string" },
                address1: { type: "string" },
                address2: { type: "string" },
                city: { type: "string" },
                postcode: { type: "string" },
            }
        },
        orders: {
            elements: {
                properties: {
                    id: { type: "string" },
                    name: {type: "string"},
                    status: { type: "string" },
                    createdAt: { type: "string" },
                    updatedAt: { type: "string" },
                    sessionId: { type: "string" }
                }
            }
        },
        sessionId: { type: "string" }
    }
}

export const serializeCart = ajv.compileSerializer(cartStateSchema);
export const parseCart = ajv.compileParser(cartStateSchema);

export interface Order {
    id: string
    name: string
    status: string
    createdAt: string
    updatedAt: string
    sessionId: string
}

export interface CartState {
    items: Record<string, number>
    customer: Customer
    orders: Order[]
    sessionId: string
}
