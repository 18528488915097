import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import BasicLayout from "../layouts/basic";

export default function AboutUsPage() {
    return (
        <BasicLayout>
            <Container className="py-5">
                <Row>
                    <Col>
                        <h2 className="text-center text-uppercase page-title">About Us</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ offset: 1, span: 10 }}>
                        <Image className="float-start me-3" src="/images/about_us.jpeg" width={300} />
                        <p className="my-3">
                            <strong>Tre Colori - "Three Colours" 🇮🇹</strong>
                        </p>
                        <p>
                            We make our authentic sourdough with the natural yeast and it’s cooked in the ever flavour wood fried oven.
                        </p>
                        <p>
                            We believe in quality therefore all our ingredients and raw materials comes from different places and cities of Italy.
                        </p>
                    </Col>
                </Row>
            </Container>
        </BasicLayout>
    );
}
